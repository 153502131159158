import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV4 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
				<div className="container">
				<div className="row">
					<div className="col-lg-6 align-self-center">
					<div className="about-us-img-wrap about-img-left">
						<img src={publicUrl+"assets/img/others/armauzun.jpeg"} alt="About Us Image" width={1450} height={750} />
						<div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
						{/* <div className="ltn__video-img ltn__animation-pulse1">
							<img src={publicUrl+"assets/img/others/8.png"} alt="video popup bg image" />
							<a className="ltn__video-icon-2 ltn__video-icon-2-border---" href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0" data-rel="lightcase:myCollection">
							<i className="fa fa-play" />
							</a>
						</div> */}
						</div>
					</div>
					</div>
					<div className="col-lg-6 align-self-center">
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-20">
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-colorx">Hakkımızda</h6>
						<h1 className="section-title">Kocaeli Arma Diş
							</h1>
						<p>2020 yılında dokunduğumuz herkesi gülümsetmek için Özel Arma Ağız ve Diş Sağlığı Merkezinin kapılarını sizlere açtık.




Ağız ve diş sağlığı ile ilgili tüm ihtiyaçlarınıza cevap verebilmek için son teknoloji cihazlarla donanmış modern merkezimizde amacımız; her yaştan hastaya değerli olduğunu hissettirerek, ihtiyaç duyduğu tedaviyi en iyi şekilde sağlamaktır.
Uzman hekimlerimiz kendilerini her geçen gün yenileyerek; genel diş hekimliği, radyoloji, restoratif diş tedavisi, endodonti (kanal tedavisi), çocuk diş hekimliği (pedodonti), ortodonti, implant, ağız ve çene cerrahisi, estetik diş hekimliği alanlarında hizmet vermektedir.<br></br>
						<br></br><h1>Vizyonumuz</h1> Etik ve küresel değerler içerisinde, ağız ve diş sağlığı alanında üst düzey tedavi sağlayarak örnek alınan, kendini sürekli yenileyen bir ağız ve diş sağlığı merkezi olarak hizmet vermektedir.<br></br><br></br><h1>Misyonumuz</h1>Hasta haklarını titizlikle gözeterek, güncel ekipman ve tekniklerle tedavi hizmetleri sunmak.


Yaşadığımız toplumda ağız ve diş sağlığı konusunda bir bilinç oluşturmak.


Meslektaşlarımızın haklarına saygı duyarak, etik değerler çerçevesinde sağlık hizmeti vermek.


Sivil toplum kuruluşları ile işbirliği yaparak, içinde bulunduğumuz topluma değer katmak.
						 
									</p>
						</div>
						
						
						<div className="btn-wrapper animated">
						
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default AboutV4