import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import TeamDetails from './section-components/team-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import ProductSlider from './shop-components/product-slider-v1';
import Footer from './global-components/footer';
import { useParams } from 'react-router-dom';
import { teamList } from '../helper/staticDataList';
const Team_Details = () => {
  const { id } = useParams();
  const [teamDetail, setTeamDetai] = React.useState({});

  const findArrayElementByTitle = (list, id) => {
    list.forEach(element => {
      if (element?.id?.toString() === id) {
        setTeamDetai(element);
      }
    });
  };
  React.useEffect(() => {
    findArrayElementByTitle(teamList, id);
  }, []);
  return (
    <div>
      <Navbar />
      <ProductSlider pictureList={teamDetail?.imageList} />
      {/* <PageHeader headertitle="Agent Details" /> */}
      <hr></hr>
      <TeamDetails teamDetail={teamDetail} />
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default Team_Details;
