import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { articleList, productList } from '../../helper/staticDataList';

class ShopDetails extends Component {

    render() {
		const itemDetail =this.props?.itemDetail
        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__shop-details-area pb-10">
				<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12">
					<div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
						<div className="ltn__blog-meta">
						<ul>
							{/* <li className="ltn__blog-category">
							<Link to="#">Featured</Link>
							</li> */}
							{/* <li className="ltn__blog-category">
							<Link className="bg-orange" to="#">For Rent</Link>
							</li> */}
							<li className="ltn__blog-date">
							
							</li>
							{/* <li>
							<Link to="#"><i className="far fa-comments" />35 Comments</Link>
							</li> */}
						</ul>
						</div>
						<h1>{itemDetail.title}</h1>
						<label><span className="ltn__secondary-color"><i className="flaticon-pin" /></span> { itemDetail.localAdress}</label>
						<h4 className="title-2">Bilgilendirme</h4>
						<p>{itemDetail.desc}</p>
						<h5 className="title-2">{itemDetail.subTitle}</h5>

						<p>{itemDetail.subDesc}</p>

						<h4 className="title-2">Galeri</h4>
						<div className="ltn__property-details-gallery mb-30">
							<div className="row">
								{itemDetail?.inImageList?.map((row) => {
									return (
										<div className="col-md-6">
										<a href={row} data-rel="lightcase:myCollection" width={"100%"}>
											<img className="mb-30" src={row} alt="Image" />
										</a>
										</div>
									)
								})}
					
				
								
						</div>
						</div>
						
						<h4 className="title-2">Konum</h4>
						<div className="property-details-google-map mb-60">
						<iframe src={itemDetail.google} width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
						</div>


						{/* APARTMENTS PLAN AREA END */}
						<h4 className="title-2">Tanıtım Videosu</h4>
						<div className="ltn__video-bg-img ltn__video-popup-height-500 bg-overlay-black-50 bg-image mb-60" data-bs-bg="assets/img/armadis_ana.png">
						<a className="ltn__video-icon-2 ltn__video-icon-2-border---" href="" data-rel="lightcase:myCollection">
							<i className="fa fa-play" />
						</a>
						</div>
						
					</div>
					</div>
					
				</div>
				</div>
			</div>
        }
}

export default ShopDetails