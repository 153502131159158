import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Testimonial extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70" >
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-colorxx">Kocaeli Arma Diş</h6>
			          <h1 className="section-title">Geri dönüşlerimiz</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Arma Diş ile ağrısıyla kıvrandıran 20 lik dişimi çektirmem ile tanıştım.Diş ağrısı ile diş hastanesinin aciline gitmiştim.gömülü diş olduğu için cerrahi işlem olduğunu ve uzmanın yapması gerektiğini söylediler.Ahmet Kıvrak için randevu aldım ve o berbat ağrıdan kurtuldum.Sonra ise dolgulu dişimin kırılmasıyla tekrar Ahmet Bey'e randevu aldım, implant tedavim devam ediyor.Diş tedavisi insanı korkutur ve o koltukta gerilebiliyoruz.Ancak korkarak gitmiyorum,Ahmet Bey'in işinde uzman olması, her aşamayı anlatması, işlem sonrasında herhangi bir soruda ulaşılabilir olması bana güven veriyor.Çok kıymetli bir diş hekimi, uzmanlığını güvenin ve diş ağrılarınıza "elveda" deyin.ayrıca asistanları, kliniği tüm çalışanları da çok ilgili, onlara da teşekkürler..</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Özlem Öztürk</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Özel Arma Diş Hastanesi çalışanlarına ve özellikle doktorum Azize hanım ve ekibi Şule Hanım ile Mukadder Hanım’a çok teşekkür ederim. Tüm tedavimle çok ilgili ve titizlikle yaklaştılar. Güleryüzleriyle hastaneye renk katıyorlar. Sağlığımı size emanet ettiğim için çok memnunum. Çevremdeki herkese hiç şüphe etmeden önerdim.Böyle sağlıkçıların sayılarının artması dileğiyle..</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Ebrar Sevde Tura</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Kurumdaki herkes oldukca ilgili alakali.Kapidan girdiginiz anda farkedildiginiz bir kurum.Ozellikle dolgu Uzmanı Tuğba Congara Kivrak ve asistaniyla 2 ay tedavi surecim oldu .Her yapilan islem titizlikle yapildi ozellikle ön disim o kadar guzel yapildi ki uzun yillardir gulumsemeye imtina etmekten kurtuldum. Doktorumun titizligi ve guven veren aciklamalari olsun beni agirlamasi olsun tum fobilerime son verdi.İyiki yolum kesismis.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Bilgen Saral</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Kocaeli de bir çok araştırmalar yaparak ve muayenelere giderek son durak olarak bulmuş olduğum Arma Diş Polikliniğini kesinlikle herkese tavsiye ediyorum.Öncelikle muayene ve tedavi planlaması yapan doktor Bilgehan beye çok teşekkür ederim.Annem odadayken cerrah Ahmet bey ve protez uzmanı Can beyin odaya gelerek hep beraber planlama yapmaları beni emin ellerde olduğumu hissettirmişti.Anneme yapılan ön bölge implant gülüş tasarımları estetik zirkonyum kronlar ve kanal dolgu işlemleri titiz bir şekilde yapılarak bu gün tedavimiz son bulmuştur.Bundan sonraki süreçte başta annem olmak üzere tüm yakınlarımın ve sevdiklerimin hiç araştırma yapmadan gideceğimiz tek adres Arma Diştir.Bu arada kliniğin temizliği ve çalışanların ilgi alakasını da söylemeden geçemiyiceğim.Tekrardan tüm hekimlere çok teşekkür ediyorum ellerinize sağlık iyi ki Arma Diş..</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Ömer Ayyıldız</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						özel arma diş hastanesi çalışanlarına özellikle doktorumuz Kemal bey ve asistanı Bayram beye ve Tuğçe hanıma çok teşekkür ederiz güler yüzlü samimi yaklaşımları sayesinde dişçi korkumuzu yendik annem ve benim implant tedavimiz mükemmel şekilde sonuçlandı işlerini özenle titizlikle yapıyorlar  kesinlikle tavsiye ediyoruz ayrıca dişeti iltihabı ve dolgu tedavim içinde Sebahattin beye çok teşekkür ediyorum sizleri çok sevdik iyiki tanımışız sizin sayenizde güzel gülüşlerimiz oldu..</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Sezen Erol</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						İki gün önce kontrol ve diş taşı temizliği için ilk kez gittim. Randevu saatini geçirmeden muayeneye geçtim. Tuğba hanım tarafından muayene ve diş taşı temizliği gerçekleştirildi. Muayene sonrası gayet aydınlatıcı ve anlaşılır şekilde bilgi verdi. Diş taşlatı çok ve diş etinin altına kadar ilerlemiş olmasına rağmen tuğba hanımın eli hafifti ve acısız bir şekilde işlemi bitirdi. İşlemden memnun kaldım. Başarılar dilerim...</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Kamil Serdar Atak</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Kocaeli'nin en iyisi tereddüt etmeden gidebilirsiniz. Bütün personelin güler yüzü, ilgisi yeter :) Kemal Hocam iyi ki tanımışım sizi teşekkür ederim her şey için..
</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Kaan Solmaz</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Öncelikle Azize Hanım ve Can Bey olmak üzere kliniğin bütün çalışanlarından oldukça memnun kaldım. Harika bir ekip. Uzmanlarımıza ve tüm çalışanlara ilgisi ve özverisi için teşekkür ederim..</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Firdes Zeren</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Kesinlikle memnun kalacağınız hekimleri ile, yüzde yüz memnun olarak ayrılacağınız diş kliniklerinden.
Özellik Ahmet Kıvrak işinin ehli güzide uzman diş hekimlerinden.
Teşekkürler Kocaeli Arma Diş
</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>İsmail Sarıkaya</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Yakınımızin tavsiyesi uzerine gittim.Implant tedavime Ahmet beyle basladik.Ben halen  disimin ceklidigini sanirken meger implantim yapilmis.Anlamadan islem bitmis bile.Gercekten cok nazik icten ve ilgili bir ekip.Henuz tedavim bitmedi.Ahmet bey mukemmel bir hekim.Tavsiye ediyorum kesinlikle.Arastirmaniza gerek yok gidip direk tedavinizi baslatin.Tertemiz disiplinli calisan bir poliklinik.Ahmet beye tesekkurlerimi sunuyorum.Evett  tedavim bitti.Can beye cook tesekkür ediyorum.Çok memnun kaldim.Dislerim takildi ve cok begendim ve begenildi</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Filiz Şirin</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Azize hanım hersey için teşekkür ederim güler yüzlü eli hafif bir okadarda ilgili herkese tavsiye ederim.Saglikli günler dilerim herkese.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Tolga Baylan</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Araştırmalarım sonucunda arma diş e gittim. Doktorum can bey ile tanıştım. Can bey işinde çok başarılı, ince ayrıntıları düşünen, iletişimi çok güzel, güven veren bir doktor. Artık çok rahat gülebiliyorum. Dişlerim çok beğeniliyor. Kesinlikle tavsiye ederim. Teşekürler Can Bey..</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Nermin Şahin</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Uz. Dt. Ahmet Kıvrak Hocamı şiddetle tavsiye ediyorum. Tereddüt etmeden o operasyon koltuğuna oturabilirsiniz. Sorunlu 20 lik gömülü dişimi tecrübesiyle hiç bir sorun yaşamadan çekti. Sonrasında herhangi bir şişlik, apse ve ağrı olmadı. İlk defa diş operasyonu geçiyorum ve işlem 20 lik dişimdi. Hasasiyeti ve babacan tavrıdan dolayı kendisine burdan da çok teşekkür ederim. İyi ki varsın Ahmet hocam..</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Batuhan Serdaroğlu</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Çok temiz ve ilgili bir klinik ben Bilgehan beye muayene oldum gerçekten işini titizlikle ve özenle yapıyor eşim Tuğçe hanımın hastası kendisinden de çok memnunuz güler yüzleri ilgileri ve yaptıkları başarılı işlemlerden dolayı minnettarız..</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Ayşe Yazıcı</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Kemal korucuoglu ve esı tugce korucuogluna butun korkularınızı yenerek kendınızı teslim edebılırsınız hersey ıcın tesekkur ederım sımdıye kadar bosuna beklemısım. Kendı dışlerim gıbı tekrar tekrar tesekkur ederım.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Selma Uğurlu</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Yaklasik 10 yildir tanidigim ve farkli nedenlerle defalarca tedavimi gerceklestiren Kemal bey ve Tuğçe hanim ile Sebahattin bey tarafindan dis tedavimi ve bakimlarimi duzenli olarak takip ediyoruz. Kaybetmek uzere oldugim azi disimi Kemal bey saatlerce suren seanslar sonunda kurtarabildi. Sadece o dis icin bile cok minnettarim. Daha sonra baska bir disim kokten kirilmisti. Hem operasyonu hem de implant tedavimi Tuğçe hanim gerceklestirdi. Eli oldukca hafif. Agri ve aci olmadan (ayrica sansima yanagim sismeden) implant tedavisini tamamladik. Sebahattin bey ise dolgu tedavimi gerceklestirdi. Her birisi alaninda gercek manada uzmanlar. Ayrica  guler yuzleri sayesinde tedavi sürecindeki stresinizi rahatlatiyorlar. Dis hekimlerine ilave olarak klinigin pandemi surecinde hasta oncesi ve sonrasinda ozon ile dezenfekte edildigini kendi gozlerimle gordum. Her kati zevk ile ferah bir sekilde donatilmis, ic acici bir merkez. Eger dis ile ilgili bir sorununuz varsa burada profesyonel bir sekilde cozum bulabileceginizden eminim..</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Gökhan Dündar</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Azize Tuna hocamıza gittim diş problemim için ve haşa gidiyorum o kadar Güler yüzlü ve işinin ehli bir doktorumuz ki sonra sın eşim komşularım iş arkadaşlarıma da öneri de bulun dum ve hep birlikte çok memnun kaldık kesinlikle tavsiye ederim.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Ümit Aydın</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Doktorum Kemal Korucuoğlu ve Tuğçe Duygu Korucuoğlu ya tavsiye üzerine gittim o kadar şanslıyım ki dişlerimde çok büyük sıkıntılar vardı gülemiyordum bile Kemal bey siz hiç merak etmeyin artık çok güzel gülüşleriniz olacak dedi ben olurmu ki acaba diyordum uzun bir süreç başladı sonuç mükemmel oldu,gerçekten kendi dişlerim gibi oldu çok titiz ve itinalı çalıştı canım doktorum cok teşekkürler Bayram bey sizede cok teşekkür ederim bu arada kanal tedavilerimi yapan Bilgehan bey sizlerede çok teşekkür ederim klinikte bütün çalışanlar güler yüzlü ve sıcakkanlilar temizliği titizliği ile çok güzel bir çalışma içerisindeler
Samimi duygularımla söylüyorum çok  çok teşekkürler hepinizden Allah razı olsun
						</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Perihan Vural</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Çok temiz hijyenik bir kuruluş, personel güleryüzlü, Tuğba hanım beni muayene etti çok ilgili kibar ve profesyonel bir doktor..</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Melika Ak</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      {/*  */}
			    </div>
			  </div>
			</div>

        }
}

export default Testimonial