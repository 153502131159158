import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class BannerV3 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/';
    let imagealt = 'image';

    return (
      <div className='ltn__slider-area ltn__slider-3  '>
        <div className='ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1'>
          {/* ltn__slide-item */}
          <div
            className='ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-70'
            data-bs-bg={publicUrl + 'assets/img/armaxxtoplu.jpg'}
          >
            <div className='ltn__slide-item-inner text-center'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12 align-self-center'>
                    <div className='slide-item-info'>
                      <div className='slide-item-info-inner ltn__slide-animation'>
                        <div className='slide-video mb-50 d-none'>
                          <a
                            className='ltn__video-icon-2 ltn__video-icon-2-border'
                            href='https://www.youtube.com/embed/tlThdr3O5Qo'
                            data-rel='lightcase:myCollection'
                          >
                            <i className='fa fa-play' />
                          </a>
                        </div>

                        <h1 className='slide-title animated '>
                          <br></br>
                          <br></br>
                          <br></br>
                          <br></br>
                          Sizin Arma'nız Gülüşünüz
                        </h1>
                        <div className='slide-brief animated'>
                          <br>
                          </br>
                          <br></br>
                          <br></br>
                          <br>
                        </br>
                        <br></br>
                        <br></br>
                        <br></br>
                        
                        
                        </div>
                        <div className='btn-wrapper animated go-top'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ltn__slide-item */}
          <div
            className='ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60'
            data-bs-bg={
              publicUrl + 'assets/img/zerayEffect/inside/cocukdenti.jpg'
            }
          >
            <div className='ltn__slide-item-inner  text-center text-end'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12 align-self-center'>
                    <div className='slide-item-info'>
                      <div className='slide-item-info-inner ltn__slide-animation'>
                        <h1 className='slide-title animated '>
                          Sizin Arma'nız <br></br>Gülüşünüz
                          <br />
                        </h1>
                        <div className='slide-brief animated'>
                          <p>
                            
                          </p>
                        </div>
                        <div className='btn-wrapper animated go-top'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ltn__slide-item */}

          {/*  */}
        </div>
      </div>
    );
  }
}

export default BannerV3;
