import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { teamList } from '../../helper/staticDataList';

class TeamV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__team-area pt-110--- pb-90">
				<div className="container">
			<div className="row justify-content-center go-top">
				{
					teamList.map((row) => {
						return (
							<div className="col-lg-4 col-sm-6">
							<div className="ltn__team-item ltn__team-item-3---">
								<div className="team-img">
								<Link to={ `/team-details/${row.id}`}><img src={row.image} alt="Image"  /></Link>
										
								</div>
								<div className="team-info">
										<h4>{row.name}</h4>
								<h6 className="ltn__secondary-color">{row.position}</h6>
								<div className="ltn__social-media">
									<ul>
									
									</ul>
								</div>
								</div>
							</div>
							</div>
						)
					})
					}
	


				</div>
				</div>
			</div>
        }
}

export default TeamV2