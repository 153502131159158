import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
class CopyRight extends Component {
	constructor(props){ 
		super(props) 
			
		// Set initial state 
		this.state = {modelOpen : 
			  false} 
			
		// Binding this keyword 
		this.closeModel = this.closeModel.bind(this)
		this.openModel = this.openModel.bind(this)
		// this.closeModel = this.closeModel.bind(this) 
		// this.openModel = this.openModel.bind(this) 
		
	  } 
		
	   closeModel() {
		this.setState({modelOpen : false}) 
		}
	 openModel() {
		this.setState({modelOpen : true}) 
		}
	render() {
		const customStyles = {

			content: {
				top: '50%',
			  left: '50%',
			  right: 'auto',
			  bottom: 'auto',
			  marginRight: '-50%',
			  transform: 'translate(-50%, -50%)',
			},
		  };


        let publicUrl = process.env.PUBLIC_URL+'/'
		let subtitle;
		
	  
        return (
			<div className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
			<div className="container-fluid ltn__border-top-2">
				<div className="row">
				<div className="col-md-6 col-12">
					<div className="ltn__copyright-design clearfix">
					<p>Tasarım @Vi Nå Agency </p>
					</div>
				</div>
				<div className="col-md-6 col-12 align-self-center">
					<div className="ltn__copyright-menu text-end">
					<ul className="go-top">
						
						<li><a style={{cursor:"pointer"}} onClick={()=>{this.openModel()}}> &amp; x</a></li>
						
					</ul>
					</div>
				</div>
				</div>
				</div>
				
      <Modal
        isOpen={this.state.modelOpen}
        
        onRequestClose={this.closeModel}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 >YASAL UYARI VE KULLANIM KOŞULLARI</h2>
        {/* <button onClick={closeModal}>close</button> */}
        <div>

			x</div>

      </Modal>
			</div>
        )
    }
}


export default CopyRight