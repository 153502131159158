import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ContactInfo extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__contact-address-area mb-90">
				<div className="container">
				<div className="row">
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="">
						<img src={publicUrl+"assets/img/emlakresimlerix/armailetisi.png"} alt="Icon Image" width={344} height={222}/>
						</div>
						<h3>Email Adresimiz</h3>
						<p>info@ozelarmadis.com <br />
						<hr></hr>
						
						</p>
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="">
						<img src={publicUrl+"assets/img/emlakresimlerix/armailetisi1.png"} alt="Icon Image" width={344} height={222} />
						</div>
						<h3>Telefon Numaramız</h3>
						
						<p>+0 262 311 00 03</p>
						<hr></hr>
						
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="">
						<img src={publicUrl+"assets/img/emlakresimlerix/armailetisi2.png"} alt="Icon Image" width={344} height={235} />
						</div>
						<h3>Ofis Adresimiz</h3>
						<p>Yenişehir, Dönmez Sk. No:29, 41050 <br/>
						İzmit, Kocaeli<br></br>Türkiye</p>
						
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default ContactInfo