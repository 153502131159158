import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class FeaturesV1 extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'

    let customClass = this.props.customClass ? this.props.customClass :''

    return <div className={ customClass } >
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-colorx">Kocaeli Arma Diş </h6>
			          <h1 className="section-title">Hizmetlerimiz</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__custom-gutter--- justify-content-center go-top">
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/emlakresimlerix/armax41.png"} alt="#" width={333} height={258} />
			          </div>
			          <div className="ltn__feature-info "  >
			            <h3 >Kalite</h3>
			            <p>Özel Arma Ağız ve Diş Sağlığı Polikliniği olarak çağa uygun ekipmanlar ve her geçen gün kendini yenileyen hekimlerimizle hizmet vermekteyiz.</p>
			            
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/emlakresimlerix/armax42.png"} alt="#" width={333} height={233} />
			          </div>
			          <div className="ltn__feature-info">
			            <h3>Teknoloji</h3>
			            <p>Kliniğimizde son teknoloji ürünü cihazlar ve tedavi yöntemleri ile hastalarımıza konforlu ve güvenli ağız ve diş sağlığı hizmetleri sunmaktayız.</p>
			            
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/emlakresimlerix/armax43.png"} alt="#" width={333} height={233} />
			          </div>
			          <div className="ltn__feature-info">
			            <h3>Güvenilirlik</h3>
			            <p>Kliniğimizde kullandığımız cihazların güvenliğine, ekipmanlarımızın hijyenine, hekimlerimizin kullandığı koruyucu kıyafetlere titizlikle dikkat edilmektedir.</p>
			            
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default FeaturesV1