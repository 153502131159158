import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class TeamDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/';
    const doctorDetail = this.props?.teamDetail;

    return (
      <div className='ltn__team-details-area mb-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='ltn__team-details-member-info text-center mb-40'>
                <div className='team-details-img'>
                  <img src={doctorDetail?.image} alt='Team Member Image' />
                </div>
                <h2>{doctorDetail?.name}</h2>
                <h6 className='text-uppercase ltn__secondary-color'>
                  {doctorDetail?.position}
                </h6>
                <div className='ltn__social-media-3'>
                  <ul>
                    <li>
                      <a
                        href={doctorDetail?.instagram}
                        title='Instagram'
                      >
                        <i className='fab fa-instagram' />
                      </a>
                    </li>
                    <li>
                      <a
                        href={doctorDetail?.doktortakvimi}
                        title='Dribbble'
                      >
                        <img
                          src={publicUrl + 'assets/doktortx.png'}
                          color='white'
                          width={20}
                          height={19}
                        ></img>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='ltn__team-details-member-info-details'>
                <p>
                {doctorDetail?.text}
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='ltn__team-details-member-about'>
                      <ul>
                        <li>
                          <strong>Pozisyon:</strong> {doctorDetail?.pozisyon}
                        </li>
                        <li>
                          
                        </li>
                        <li>
                          
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='ltn__team-details-member-about'>
                      <ul>
                        <li>
                          <strong>Email:</strong> {doctorDetail?.email}
                        </li>

                        <li>
                          
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <hr />
                <p>
                  
                </p>
                <div className='row ltn__custom-gutter mt-50 mb-20'></div>
                <p>
                 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamDetails;
